import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import state from './store/state'
import getters from './store/getters'
import mutations from './store/mutations'
import actions from './store/actions'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  getters,
  mutations,
  state,
  actions, 
  strict: process.env.NODE_ENV !== 'production',
})

export default store

