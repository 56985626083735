import Vue from 'vue'
import VueRouter from 'vue-router' 
import VueMeta from 'vue-meta'

import store from '@/state/store' 
Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes: [
   
    {
        path: '/',
        name: 'login',
        component: () => import('./views/account/login'),
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/account/forgot-password'),
        meta: {
            guest: true
        }
    }, 
    {
        path: '/reset-password/:token',
        name: 'Reset Password',
        component: () => import('./views/reset-password/reset-password'),
        meta: {
            guest: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('./views/profile/form'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/home', 
        name: 'home',
        component: () => import('./views/dashboard/home'),
        meta: { 
            authRequired: true 
        },
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('./views/user/list-user'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/user/create',
        name: 'user-create',
        component: () => import('./views/user/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/user/edit/:id',
        name: 'user-edit',
        component: () => import('./views/user/form-edit'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/user/:id/package',
        name: 'user-package',
        component: () => import('./views/user/buy-package'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/user/:id/history-appointment',
        name: 'history-appointment',
        component: () => import('./views/user/history-appointment'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/user/:id/history-purchase',
        name: 'history-purchase',
        component: () => import('./views/user/history-purchase'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/user/:id/medical-record',
        name: 'progress-note',
        component: () => import('./views/user/progress-note'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/staff',
        name: 'staff',
        component: () => import('./views/staff/list-staff'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/staff/create',
        name: 'staff-create',
        component: () => import('./views/staff/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/staff/edit/:id',
        name: 'staff-edit',
        component: () => import('./views/staff/form-edit'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/category',
        name: 'category',
        component: () => import('./views/category/list-category'),
        meta: {
            authRequired: true
        },

    },
    {
        path: '/category/create',
        name: 'category-create',
        component: () => import('./views/category/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/category/edit/:id',
        name: 'category-edit',
        component: () => import('./views/category/form-edit'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/treatment',
        name: 'treatment',
        component: () => import('./views/treatment/list-treatment'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/treatment/create',
        name: 'treatment-crete',
        component: () => import('./views/treatment/form-create.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/treatment/edit/:id',
        name: 'treatment-edit',
        component: () => import('./views/treatment/form-edit.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/location',
        name: 'location',
        component: () => import('./views/location/list-location.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/location/create',
        name: 'create-location',
        component: () => import('./views/location/create-location.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/location/edit/:id',
        name: 'edit-location',
        component: () => import('./views/location/edit-location.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/equipment',
        name: 'equipment',
        component: () => import('./views/equipment/list-equipment.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/equipment/create',
        name: 'create-equipment',
        component: () => import('./views/equipment/create-equipment.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/equipment/edit/:id',
        name: 'edit-equipment',
        component: () => import('./views/equipment/edit-equipment.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/employee',
        name: 'employee',
        component: () => import('./views/employee/list-employee.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/employee/create',
        name: 'create-employee',
        component: () => import('./views/employee/create-employee.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/employee/edit/:id',
        name: 'edit-employee',
        component: () => import('./views/employee/edit-employee.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/room',
        name: 'Room',
        component: () => import('./views/room/list-room.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/room/create',
        name: 'create-room',
        component: () => import('./views/room/create-room.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/room/edit/:id',
        name: 'edit-room',
        component: () => import('./views/room/edit-room.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/appointment',
        name: 'appointment',
        component: () => import('./views/appointment/list-appointment.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/appointment/create',
        name: 'create-appointment',
        component: () => import('./views/appointment/create-appointment.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/appointment/edit/:id',
        name: 'edit-appointment',
        component: () => import('./views/appointment/edit-appointment.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/appointment/edit/:id/old',
        name: 'edit-appointment',
        component: () => import('./views/appointment/edit-appointment-old.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('./views/appointment/calendar-appointment'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/series',
        name: 'series',
        component: () => import('./views/series/series.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/series/create',
        name: 'create-series',
        component: () => import('./views/series/create-series.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/series/edit/:id',
        name: 'edit-series',
        component: () => import('./views/series/edit-series.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/package',
        name: 'package',
        component: () => import('./views/package/package.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/package/create',
        name: 'create-package',
        component: () => import('./views/package/create-package.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/package/edit/:id',
        name: 'edit-package',
        component: () => import('./views/package/edit-package.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/voucher',
        name: 'voucher',
        component: () => import('./views/voucher/list-voucher.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/voucher/create',
        name: 'create-voucher',
        component: () => import('./views/voucher/create-voucher.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/voucher/edit/:id',
        name: 'edit-voucher',
        component: () => import('./views/voucher/edit-voucher.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/role',
        name: 'role',
        component: () => import('./views/role/list-role'),
        meta: {
            authRequired: true
        },

    },
    {
        path: '/role/create',
        name: 'role-create',
        component: () => import('./views/role/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/role/edit/:id',
        name: 'role-edit',
        component: () => import('./views/role/form-edit'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/role/:id/permission',
        name: 'role-permission',
        component: () => import('./views/role/permission'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/buy-product',
        name: 'buy-product',
        component: () => import('./views/buy-product/list'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/buy-product/add',
        name: 'buy-product-add',
        component: () => import('./views/buy-product/form'),
        meta: {
            authRequired: true
        },
    },
    // guest
    {
        path: '/pages/404',
        name: 'Page-404',
        component: () => import('./views/pages/error-404'),
        meta: {
            guest: true,
        }
    },
    {
        path: '/pages/500',
        name: 'Page-500',
        component: () => import('./views/pages/error-500'),
        meta: {
            guest: true,
        }
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        component: () => import('./views/pages/maintenance'),
        meta: {
            guest: true,
        }
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/pages/error-404'
    }
]
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
      if (store.state.userdata == null) {
        next('/')
      }
    }
    if (to.matched.some(record => record.meta.guest)) {
      // this route check if user have logged in redirect to home page
      if (store.state.userdata !== null) {
        next('/home')
      }
    }
    next()
  })
export default router