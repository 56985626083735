import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/routes.js'
import store from '@/state/store'
import vco from "v-click-outside"
import "@/design/index.scss";
import Sparkline from 'vue-sparklines';
import cookie from 'js-cookie'
import axios from "axios";
import * as moment from 'moment';
import JsonViewer from 'vue-json-viewer';

// import { initFirebaseBackend } from './authUtils'

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_apiKey,
//   authDomain: process.env.VUE_APP_authDomain,
//   databaseURL: process.env.VUE_APP_databaseURL,
//   projectId: process.env.VUE_APP_projectId,
//   storageBucket: process.env.VUE_APP_storageBucket,
//   messagingSenderId: process.env.VUE_APP_messagingSenderId,
//   appId: process.env.VUE_APP_appId,
//   measurementId: process.env.VUE_APP_measurementId
// };

// initFirebaseBackend(firebaseConfig);

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

let axiosOptoins = {
  baseURL: process.env.VUE_APP_BASE_URL_API,
  timeout: 180000, // 3 menit
  headers: {
    Authorization: 'Bearer',
    'Content-Type': 'multipart/form-data',
    'X-timezone': 'Asia/Jakarta',
    'Accept': 'application/json'
  }
}

if (cookie.getJSON('userdata') !== undefined) {
  let auth = cookie.getJSON('userdata')
  store.commit('SET_LOGIN', auth) 
  axiosOptoins.headers.Authorization = 'Bearer ' + auth.token
}

Vue.prototype.$axios = axios.create(axiosOptoins);

Vue.prototype.$woo = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API_CONTENT,
  timeout: 180000, // 3 menit
  headers: {
    'Content-Type': 'multipart/form-data',
    'X-timezone': 'Asia/Jakarta',
    'Accept': 'application/json'
  }
})

Vue.config.productionTip = false

Vue.use(BootstrapVue);

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCdUu51ehxVIQJvfaqQ64_VVV8Zt4fWdWs',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(JsonViewer)
Vue.component('apexchart', VueApexCharts)

import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
  debug: process.env.VUE_APP_DEBUG_MODE ? process.env.VUE_APP_DEBUG_MODE : false,
  connection: process.env.VUE_APP_BASE_URL_API,
  options: { 
    path: "/socket" 
  } //Optional options
}))

function convertToRupiah (angka, prefix = true) {
  var rupiah = '';		
  var angkarev = angka.toString().split('').reverse().join('');
  for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
  return (prefix)? 'Rp. ' : '' +rupiah.split('',rupiah.length-1).reverse().join('');
}
function convertToAngka (rupiah) {
  if (rupiah == "") {
    return 0
  } else {
    return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
  }
}

//filter
Vue.filter('textLength', function (value) {
  if (value !== null && value.length > 30) {
    return value.substr(0, 30) + '...'
  } else {
    return value
  }
});

Vue.filter('convertToRupiah', function (value) {
  let rupiah = '';
  var angkarev = value.toString().split('').reverse().join('');
  for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
  return rupiah.split('',rupiah.length-1).reverse().join('');
});

Vue.filter('convertToAngka', function (rupiah) {
  if (rupiah == "") {
    return 0
  } else {
    return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
  }
})

Vue.filter('capitalize', function (string) {
  if (string) {
    return string.replace(/_/g, ' ').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  } else {
    return null
  }
})

Vue.filter('date_only', function(date) {
  return moment(date).format('DD MMMM YYYY')
})

Vue.filter('bulan', function(date) {
  return moment(date).format('MMM')
})

Vue.filter('tanggal', function(date) {
  return moment(date).format('DD')
})

Vue.filter('time_only', function (date) {
  return moment(date).format('HH:mm:ss')
})

Vue.filter('date_time', function (date) {
  return moment(date).format('DD MMMM YYYY HH:mm:ss')
})

Vue.mixin({
  data() {
    return {
      productUrl: `${process.env.VUE_APP_BASE_URL_API_CONTENT}/wp-json/surface/v1/products`
    }
  },
  methods: {
    convertToRupiah: (angka, prefix = true) => {
      var rupiah = '';		
      var angkarev = angka.toString().split('').reverse().join('');
      for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
      return (prefix)? 'Rp. ' : '' +rupiah.split('',rupiah.length-1).reverse().join('');
    },
    convertToAngka: (rupiah) => {
      if (rupiah == "") {
        return 0
      } else {
        return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
