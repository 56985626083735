const getters = {
    myPermission: (state) => {
        if (state.userdata.role.name == 'admin') {
            return state.permission
        } else {
            return state.userdata.role.permissions
        }
    },
    checkPermission: (state, getters) => (permission) => {
        return getters.myPermission.includes(permission);
    },
    getStaffLocationId: (state) => {
        return (state.userdata.locationId)? state.userdata.locationId : null;
    },
    getStaffLocationName: (state) => {
        return (state.userdata.locationName)? state.userdata.locationName : null;
    }
}

export default getters
