
const mutations = {

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  SET_LOGIN (state, payload) {
    state.userdata = payload
  },
  UPDATE_PROFILE(state, payload) {
    state.userdata.name = payload.name
    state.userdata.phone = payload.phone
    state.userdata.email = payload.email
  },
  SET_TOGGLE(state, val){
    state.activeToggle = val
  }
  // ////////////////////////////////////////////
  // COMPONENT
  // //////////////////////////////////////////// 
}

export default mutations
